import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Posts from '../components/Posts'
import { colors } from '../constants/theme'
import { IPost } from '../interfaces'

const ContainerTag = styled.div`
  font-size: 25px;
`

const ContainerTagName = styled.span`
  color: ${colors.BLUE};
  text-decoration: underline;
`

interface IPageContext {
  posts: [IPost]
  tag: string
}

function PostsByTagTemplate({
  pageContext,
  location,
}: {
  pageContext: IPageContext
  location: Location
}) {
  const { posts, tag } = pageContext
  const tagCapitalized = tag.charAt(0).toUpperCase() + tag.slice(1)

  return (
    <Layout location={location}>
      <ContainerTag>
        <span>Filtering by tag: </span>
        <ContainerTagName>{tagCapitalized}</ContainerTagName>
      </ContainerTag>
      <Posts posts={posts} />
    </Layout>
  )
}

export default PostsByTagTemplate
