import React from 'react'
import styled from 'styled-components'

import { colors } from '../../constants/theme'
import { IPost } from '../../interfaces'
import Link from '../Link'
import PostHeader from '../PostHeader'

const Container = styled.div`
  border-bottom: 1px solid ${colors.GREY_BORDER};
  height: 250px;

  @media (max-width: 830px) {
    height: 350px;
  }
  @media (max-width: 450px) {
    height: 550px;
  }
  @media (max-width: 350px) {
    height: 600px;
  }
  margin-top: 20px;
`

const ContainerExcerpt = styled.div`
  margin-top: 30px;
`

const ContainerReadMore = styled.span`
  margin-left: 5px;
`

function Post({ post }: { post: IPost }) {
  const { frontmatter, excerpt } = post

  return (
    <Container>
      <PostHeader post={post} isTitleClickable={true} />
      <ContainerExcerpt>
        <span>{excerpt}</span>
        <ContainerReadMore>
          <Link to={frontmatter.path} color={colors.BLUE}>
            continue reading
          </Link>
        </ContainerReadMore>
      </ContainerExcerpt>
    </Container>
  )
}

export default Post
