import React from 'react'
import styled from 'styled-components'

import { IPost } from '../../interfaces'
import Post from '../Post'

const Container = styled.div``

const Posts = ({ posts }: { posts: IPost[] }) => (
  <Container>
    {posts.map(post => (
      <Post key={`${post.id}-${post.frontmatter.title}`} post={post} />
    ))}
  </Container>
)

export default Posts
